<template>
  <div>
    <div class="vip_header">
      <van-icon
        class="header_icon"
        @click="navBack"
        size="20"
        name="arrow-left"
      />
      <div class="box">
        <div class="menu-list">
          <div
            class="total"
            :class="{ active: item.id === sel }"
            v-for="item in tabs"
            :key="item.id"
            @click="select(item)"
          >
            {{ item.label }}
          </div>
        </div>
      </div>
    </div>
    <div class="text">
      <div class="all" v-if="sel === 1">
        <div class="page-body">
          <div class="content">
            <div class="section-title">
              <span class="status"><span class="title-style">{{ examTitles[testingIdx] ? questionType[examTitles[testingIdx].questionType] : "" }}</span></span>
              <span class="title-txt">{{ examTitles[testingIdx] ? examTitles[testingIdx].questionStem : "-" }}</span>
            </div>
            <div v-if="examTitles[testingIdx] && examTitles[testingIdx].questionPic" class="section-img">
              <img :src="examTitles[testingIdx].questionPic" alt="img" />
            </div>
            <div v-if="examTitles[testingIdx]" class="section-options">
              <div
                v-for="(item, index) in examTitles[testingIdx].answers"
                :key="index"
                ref="cc"
                @click="eventBtn && chooseAnswer(index)"
                :class="['option-item', { active: item.select }]"
              >
                <span class="letter">{{ index | getLetter }}</span>
                <span class="answer">{{ item.answerText }}</span>
              </div>
              <van-button
                v-if="examTitles[testingIdx] ? examTitles[testingIdx].questionType === 2 : ''"
                block
                round
                type="primary"
                class="determine"
                @click.native="testing"
                :disabled="isBtn"
              >确认答案</van-button>
            </div>
            <div class="btn-wrap">
              <img @click="prev" class="btn default" src="../../assets/icon/last.png"/>
              <img @click="next" class="btn" src="../../assets/icon/next.png"/>
            </div>

            <div class="section-desc">
              <div @click="showPopup" class="right-wrap">
                <div class="right right-answer">
                  <img :src="require('../../assets/icon/icon-right'+(appno==1 ? '' : appno)+'.png')" alt="icon" />
                  <span>{{ rightCount }}</span>
                </div>
                <div class="right wrong-answer">
                  <img src="@/assets/icon/icon-wrong.png" alt="icon" />
                  <span>{{ wrongCount }}</span>
                </div>
                <div class="right">
                  <van-icon style="margin-right: 6px" name="apps-o" size="20" />
                  <span>{{ testingIdx + 1 }} / {{ examTitles.length }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="panel-wrap" v-if="errorprompt">
          <div :class="!rightAndYour[testingIdx].check ? 'wrong' : 'iswrong'">{{ rightAndYour[testingIdx]!=undefined && !rightAndYour[testingIdx].check ? '回答错误' : '回答正确' }}</div>
          <div class="right-key">你选择:
              <span class="seen">{{ rightAndYour[testingIdx].u }}</span>
          </div>
          <div class="right-key">正确答案:
            <span
              v-for="(item, index) in examTitles[testingIdx].answers"
              :key="index"
            >
              <span :class="item.ifCorrenct==='0' ? 'seen' : 'unseen'">{{ index | getLetter }}</span>
            </span>
          </div>
          <div class="problem">本题技巧
            <div class="tip">{{ examTitles[testingIdx].questionAnalyse }}</div>
          </div>
          <div class="explain-detai">试题详解</div>
          <!-- <div class="video-analysis">视频解析
            <div class="video-wrap"></div>
          </div> -->
          <div class="topic-analysis">题目解析
            <div class="tip">{{ examTitles[testingIdx].questionAnalyse }}</div>
            <div class="difficulty" v-if="examTitles[testingIdx].questionDifficulty===1">简单</div>
            <div class="difficulty" v-else-if="examTitles[testingIdx].questionDifficulty===2">一般</div>
            <div class="difficulty" v-else>困难</div>
          </div>
        </div>
        <van-popup
          v-model="titlePopup"
          position="bottom"
          :style="{ height: '40%' }"
        >
          <div class="popup-wrap">
            <div class="section-desc">
              <div class="right-wrap">
                <div class="right right-answer">
                  <img :src="require('../../assets/icon/icon-right'+(appno==1 ? '' : appno)+'.png')" alt="icon">
                  <span>{{ rightCount }}</span>
                </div>
                <div class="right wrong-answer">
                  <img src="@/assets/icon/icon-wrong.png" alt="icon">
                  <span>{{ wrongCount }}</span>
                </div>
                <div class="right">
                  <van-icon style="margin-right:6px" name="apps-o" size="20" />
                  <span>{{ testingIdx+1}}/{{examTitles.length }}</span>
                </div>

              </div>
            </div>
            <div class="section-cicle">
              <span v-for="(item,index) in examTitles" @click="gotitle(index)" :key="index" :class="[`cicle-index ${ rightAndYour[index].u !== '' ? rightAndYour[index].check === true?'cicle-correct':'cicle-error':''}`, {active:index==testingIdx}]">
                {{ index+1 }}
              </span>
            </div>
          </div>
        </van-popup>
      </div>

      <div class="all" v-if="sel === 2">
        <div class="page-body">
          <div class="content">
            <div class="section-title">
              <span class="status"><span class="title-style">{{ examTitles[testingIdx] ? questionType[examTitles[testingIdx].questionType] : "" }}</span></span>
              <span class="title-txt">{{ examTitles[testingIdx] ? examTitles[testingIdx].questionStem : "-" }}</span>
            </div>
            <div v-if="examTitles[testingIdx] && examTitles[testingIdx].questionPic" class="section-img">
              <img :src="examTitles[testingIdx].questionPic" alt="img" />
            </div>
            <div v-if="examTitles[testingIdx]" class="section-options">
              <div
                v-for="(item, index) in examTitles[testingIdx].answers"
                :key="index"
                :class="['option-item', { active: item.select }]"
              >
                <span :class="item.ifCorrenct==='0' ? 'correct' : 'nocorrect'">{{ index | getLetter }}</span>
                <span class="answer">{{ item.answerText }}</span>
              </div>
            </div>

            <div class="btn-wrap">
              <img @click="prev" class="btn default" src="../../assets/icon/last.png"/>
              <img @click="next" class="btn" src="../../assets/icon/next.png"/>
            </div>

            <div class="section-desc">
              <div @click="showPopup" class="right-wrap">
                <div class="right">
                  <van-icon style="margin-right: 6px" name="apps-o" size="20" />
                  <span>{{ testingIdx + 1 }} / {{ examTitles.length }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="panel-wrap" v-if="examTitles[testingIdx]">
          <div class="right-key">正确答案:
            <span
              v-for="(item, index) in examTitles[testingIdx].answers"
              :key="index"
            >
              <span :class="item.ifCorrenct==='0' ? 'seen' : 'unseen'">{{ index | getLetter }}</span>
            </span>
          </div>
          <div class="problem">本题技巧
            <div class="tip">{{ examTitles[testingIdx].questionAnalyse }}</div>
          </div>
          <div class="explain-detai">试题详解</div>
          <!-- <div class="video-analysis">视频解析
            <div class="video-wrap"></div>
          </div> -->
          <div class="topic-analysis">题目解析
            <div class="tip">{{ examTitles[testingIdx].questionAnalyse }}</div>
            <div class="difficulty" v-if="examTitles[testingIdx].questionDifficulty===1">简单</div>
            <div class="difficulty" v-else-if="examTitles[testingIdx].questionDifficulty===2">一般</div>
            <div class="difficulty" v-else>困难</div>
          </div>
        </div>
        <van-popup
          v-model="titlePopup"
          position="bottom"
          :style="{ height: '40%' }"
        >
          <div class="popup-wrap">
            <div class="section-desc">
                <div @click="showPopup" class="right-wrap">
                  <div class="right">
                    <van-icon style="margin-right: 6px" name="apps-o" size="20" />
                    <span>{{ testingIdx + 1 }} / {{ examTitles.length }}</span>
                  </div>
                </div>
            </div>
            <div class="section-cicle">
              <span v-for="(item,index) in examTitles" :key="index" @click="gotitle(index)" :class="['cicle-index', { active:index==testingIdx }]">{{ index+1 }}</span>
            </div>
          </div>
        </van-popup>
      </div>
    </div>
  </div>
</template>

<script>
import { getConciseQuestion } from '../../api/user';
export default {
  name: "vipexam",
  data() {
    return {
      pageTitle: "",
      sel: 1,
      tabs: [
        { label: "答题模式",  id: 1 },
        { label: "背题模式", id: 2 },
      ],
      // showPop: false,
      titlePopup: false,
      errorprompt: false,
      eventBtn: true,
      testingIdx: 0,
      examTitles: [],
      answers: [],
      rightAndYour: [],
      questionType: {
        1: "单选题",
        2: "多选题",
        3: "判断题",
      },
      answerid: 0,
      rightCount:0,
      wrongCount:0,
      isBtn: true,
    };
  },
  components: {},
  created() {
    this.subject = this.$route.query.subject;
    this.categoryId = this.$route.query.categoryId;
    // 获取精简500题
    this.getConciseQuestion();
  },
  computed: {
    appno() {
      return this.$store.state.app.appno;
    },
    rightAnswer() {
      const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      let ans = '';
      this.answers.map((v,i) => {
        if (v.ifCorrenct == 0) {
          ans += letters.charAt(i);
        }
      })
      return ans;
    },
    yourAnswer() {
      const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      let ans = '';
      this.answers.map((v,i) => {
        if (v.select) {
          ans += letters.charAt(i);
        }
      })
      return ans;
    }
  },
  mounted() {
  },
  methods: {
    // 获取精简500题
    getConciseQuestion() {
      getConciseQuestion(
        this.categoryId,
        this.subject
      ).then((data) => {
        this.examTitles = data;
        for (var i = 0; i < data.length; i++) {
          var add = {
            r: this.rightAnswer,
            u: this.yourAnswer,
            check: '',
            t: '',
            c: i,
          };
          this.rightAndYour.push(add);
        }
        console.log(this.rightAndYour);
			}).catch(() => {
			})
    },
    // 点击回退
    navBack() {
      this.$router.go(-1);
    },
    select(item) {
      this.sel = item.id;
      if (this.sel === 2) {
        this.answerid = this.testingIdx;
      } else {
        this.testingIdx = this.answerid;
      }
    },
    // 选择答案
    chooseAnswer(index) {
      // 选择答案
      let answers = this.examTitles[this.testingIdx].answers;
      let type = this.examTitles[this.testingIdx].questionType;
      answers.map((v, i) => {
        let flag = !!v.select;
        if (type == 2) {
          // 多选
          if (i == index) v.select = !flag;
        } else {
          // 单选 判断
          if (i == index) {
            v.select = !flag;
          } else {
            v.select = false;
          }
        }
      });
      this.examTitles[this.testingIdx].answers = answers;
      this.answers = answers;
      this.$set( this.examTitles, this.testingIdx, this.examTitles[this.testingIdx] );
      if (this.examTitles[this.testingIdx].questionType === 2) {
        this.eventBtn = true;
        this.isBtn = false;
      } else {
        this.eventBtn = false;
        this.testing();
      }
    },
    // 确定答案
    testing() {
      const answers = this.examTitles[this.testingIdx].answers;
      const answer = answers.find((v) => v.select);
      if (answer == undefined) {
        this.yourAnswer = '';
      }
      var add = {
        r: this.rightAnswer,
        u: this.yourAnswer,
        check: this.rightAnswer==this.yourAnswer,
        t: this.examTitles[this.testingIdx].questionType,
        c: this.testingIdx,
      };
      if (this.rightAndYour[this.testingIdx] !== null) {
        this.rightAndYour[this.testingIdx] = add;
      }
      this.isrightCount();
      this.iswrongCount();
      console.log(this.rightAndYour);
      console.log(this.rightAndYour[this.testingIdx]);
      if (this.rightAndYour[this.testingIdx].check === false || this.rightAndYour[this.testingIdx].check === true) {
        this.eventBtn = false;
        this.errorprompt = true;
        this.isBtn = true
      }else{
        this.eventBtn = true;
        this.errorprompt = false;
      }
      if (this.rightAndYour[this.testingIdx].check === true) {
        this.next();
      }
    },
    // 上一题
    prev() {
      if (this.testingIdx <= 0) {
        this.$dialog.alert({
          message: "已经是第一题了",
          theme: "round-button",
          confirmButtonColor: "#2db1ac",
          width: 290,
        });
        return;
      }
      this.testingIdx--;
      if (this.rightAndYour[this.testingIdx].check === false || this.rightAndYour[this.testingIdx].check === true) {
        this.eventBtn = false;
        this.errorprompt = true;
        this.isBtn = true;
      } else{
        this.eventBtn = true;
        this.errorprompt = false;
      }
    },
    // 下一题
    next() {
      if (this.testingIdx >= this.examTitles.length - 1) {
        this.$dialog.alert({
          message: "已经是最后一题了",
          theme: "round-button",
          confirmButtonColor: "#2db1ac",
          width: 290,
        });
        return;
      }
      this.testingIdx++;
      if (this.rightAndYour[this.testingIdx].check === false || this.rightAndYour[this.testingIdx].check === true) {
        this.eventBtn = false;
        this.errorprompt = true;
        this.isBtn = true;
      } else {
        this.eventBtn = true;
        this.errorprompt = false;
      }
    },
    // 跳转某道题
    gotitle(index) {
      this.testingIdx = index;
      if ((this.rightAndYour[index].check === false || this.rightAndYour[index].check === true) && this.rightAndYour[this.testingIdx].u !== '') {
        this.errorprompt = true;
        this.isBtn = true;
        this.eventBtn = false;
      } else {
        this.errorprompt = false;
        this.eventBtn = true;
      }
    },
    // 正确题数
    isrightCount() {
      let arr = this.rightAndYour.filter(v => v.check === true);
      return this.rightCount = arr.length;
    },
    // 错误题数
    iswrongCount() {
      let arr = this.rightAndYour.filter(v => v.check === false);
      return this.wrongCount = arr.length;
    },
    showPopup() {
      this.titlePopup = true;
    },
  },
};
</script>

<style lang="less" scoped>
.vip_header {
  width: 100%;
  height: 46px;
  background-color: #fff;
  position: relative;
  display: flex;
  background-color: #2db1ac;
  .van-icon {
    color: #fff;
    padding-left: 2%;
    line-height: 46px;
  }
}
.box {
  position: absolute;
  width: 40%;
  margin-left: 30%;
}
.menu-list {
  height: 46px;
  display: flex;
}
.total {
  flex: 1;
  line-height: 46px;
  flex-direction: column;
  justify-content: center;
  background: #2db1ac;
  color: #fff;
  text-align: center;
}
.total.active {
  border-bottom: 2px solid red;
}
  .text{
    flex: 1;
    overflow: auto;
  }
.page-body {
  height: 100%;
  margin-top: 0;
  padding: 20px 0 15px;
  .content {
    position: relative;
    top: -14px;
    background: #fff;
    border-radius: 14px;
    padding: 20px 15px 35px;

    .section-title {
      display: flex;
      margin-bottom: 12px;
      .status {
        flex-shrink: 0;
        margin-right: 6px;
      }
      .title-style {
        display: inline-block;
        margin-top: 2px;
        padding: 2px 6px;
        background: linear-gradient(90deg, #2db1ac, #2db1ac);
        border-radius: 17px;
        color: #fbf9f8;
        font-size: 11px;
      }
      .title-txt {
        font-size: 15px;
        color: #444444;
        line-height: 1.8;
      }
    }
    .section-img {
      margin-bottom: 10px;
      img {
        width: 100%;
      }
    }
    .section-options {
      margin-bottom: 40px;
      .option-item {
        margin-bottom: 12px;
        color: #444444;
        .letter {
          display: inline-block;
          width: 20px;
          height: 20px;
          line-height: 20px;
          text-align: center;
          font-size: 13px;
          box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
          margin-right: 8px;
          border-radius: 20px;
        }
        .correct {
          display: inline-block;
          width: 20px;
          height: 20px;
          line-height: 20px;
          text-align: center;
          font-size: 13px;
          box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
          margin-right: 8px;
          border-radius: 20px;
          color: #fff;
          background: #2db1ac;
        }
        .nocorrect {
          display: inline-block;
          width: 20px;
          height: 20px;
          line-height: 20px;
          text-align: center;
          font-size: 13px;
          box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
          margin-right: 8px;
          border-radius: 20px;
        }
        .icon-img {
          display: inline-block;
          width: 20px;
          margin-right: 8px;
          border-radius: 20px;
          box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
          vertical-align: top;
        }
        &.active {
          .letter {
            color: #fff;
            background: #2db1ac;
          }
        }
      }
    }
    .determine{
      // margin: 0 auto;
      // width: 200px;
      // height: 50px;
      // line-height: 50px;
      // text-align: center;
      // color: #fff;
      background-color: #2db1ac;
      // border-radius: 25px;
    }
    .btn-wrap {
      display: flex;
      justify-content: flex-end;
      margin: 40px 0 0;
      .btn {
        width: 70px;
        padding: 0;
        height: 30px;
        font-size: 14px;
        margin: 0 10px 0 0;
        color: #fff;
        background: linear-gradient(90deg, #2db1ac, #2db1ac);
        border-radius: 40px;
        border: 1px solid #2db1ac;
      }
      .btn.default {
        color: #2db1ac;
        border: 1px solid #2db1ac;
      }
    }
    .section-desc {
      display: flex;
      justify-content: space-between;
      position: fixed;
      bottom: 0;
      width: 100%;
      height: 60px;
      left: 0;
      padding: 0 20px;
      line-height: 60px;
      border-top: 1px solid #e1e1e1;
      background: #ffffff;
      z-index: 10;
      img {
        width: 20px;
        margin-right: 8px;
      }
      .left {
        display: flex;
        align-items: center;
      }
      .right-wrap {
        display: flex;
        position: absolute;
        right: 10px;
        top: 0;
      }
      .right {
        display: flex;
        align-items: center;
        margin-right: 12px;
        &.right-answer {
          color: #2db1ac;
        }
        &.wrong-answer {
          color: #ff1a1a;
        }
      }
    }
  }
}
.panel-wrap {
  width: 100%;
  .wrong{
    height: 50px;
    text-align: center;
    color: red;
    line-height: 50px;
    font-size: 16px;
  }
  .iswrong{
    height: 50px;
    text-align: center;
    color: #2db1ac;
    line-height: 50px;
    font-size: 16px;
  }
  .right-key{
    width: 90%;
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    font-weight: bolder;
    margin-left: 5%;
    border-bottom: 1px solid #000;
    .seen{
      color: #2db1ac;
    }
    .unseen{
      display: none;
    }
  }
  .problem{
    line-height: 30px;
    padding-left: 5%;
    font-weight: bold;
    font-size: 14px;
    .tip{
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      padding-right: 5%;
      padding-left: 0;
    }
  }
  .explain-detai{
    width: 90%;
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    font-weight: bolder;
    margin-left: 5%;
    border-bottom: 1px solid #000;
  }
  .video-analysis{
    line-height: 40px;
    padding-left: 5%;
    font-weight: bold;
    font-size: 14px;
    .video-wrap{
      width: 95%;
      height: 210px;
      border: 1px solid red;
    }
  }
  .topic-analysis{
    line-height: 40px;
    padding-left: 5%;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 20%;
    .tip{
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      padding-right: 5%;
      padding-left: 0;
    }
    .difficulty{
      line-height: 30px;
      color: #2db1ac;
      font-size: 14px;
      font-weight: normal;
    }
  }
}
.popup-wrap {
  .section-desc {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 60px;
      left: 0;
      padding: 0 20px;
      line-height: 60px;
      border-top: 1px solid #E1E1E1;
      background: #ffffff;
      z-index: 10;
      img {
        width: 20px;
        margin-right: 8px;
      }
      .left {
        display: flex;
        align-items: center;
      }
      .right-wrap {
        display: flex;
        position: absolute;
        right: 10px;
        top: 0;
      }
      .right {
        display: flex;
        align-items: center;
        margin-right: 12px;
        &.right-answer {
          color: #2db1ac;
        }
        &.wrong-answer {
          color: #FF1A1A;
        }
      }
    }
    .section-cicle {
      padding: 0 16px;
      .cicle-index {
        display: inline-block;
        width: 40px;
        height: 40px;
        background: #FFFFFF;
        border: 1px solid #AAAAAA;
        border-radius: 50%;
        margin-right: 8px;
        margin-bottom: 8px;
        text-align: center;
        line-height: 40px;
        color: #666;
        &.active {
          background: #FFA500;
          color: #ffffff;
          border-color: transparent;
        }
      }
      .cicle-correct{
        background: #2db1ac;
      }
      .cicle-error{
        background: #FF0000;
      }
    }
}

.popup-content {
  width: 290px;
  overflow: hidden;
  background: #fff;
  &>.title {
    background: linear-gradient(90deg, #2db1ac, #2db1ac);
    padding: 15px;
    font-size: 18px;
    color: #fff;
    text-align: center;
  }
  .content {
    margin: 20px 0 10px;
    padding: 0 10px;
    font-size: 17px;
    text-align: center;
    .list {
      display: flex;
      align-items: center;
      margin-top: 10px;
      span {
        display: inline-block;
        width: 50%;
      }
    }
  }
  .btn-wrap {
    display: flex;
    padding: 15px;
    .btn {
      width: 40%;
      font-weight: 500;
    }
  }
}
</style>